import { Card, Heading, VStack } from "@chakra-ui/react";
import { FormantFamily } from "../types/FormantFamilies";
import { FormantButton } from "./FormantButton";

export interface FormantFamilyCardProps {
    family: FormantFamily;
}

export const FormantFamilyCard = ({ family }: FormantFamilyCardProps) => (
    <VStack key={`${family.name}`} align="stretch">
        <Card borderWidth="1px" borderRadius="lg" p={4}>
            <Heading size="md" mb={4}>
                {family.name}
            </Heading>

            {family.formants.map((f) => (
                <FormantButton key={f.name} formant={f} />
            ))}
        </Card>
    </VStack>
);
