import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { PlayerProvider } from "../context/PlayerContext";
import theme from "../theme";
import { FormantSynth } from "./FormantSynth";

export const App = () => {
    return (
        <ChakraProvider>
            <ColorModeScript initialColorMode={theme.config.initialColorMode} />

            <PlayerProvider>
                <FormantSynth></FormantSynth>
            </PlayerProvider>
        </ChakraProvider>
    );

    // return (
    //     <RecorderProvider>
    //         <RendererProvider canvasRef={canvasRef}>
    //             <VocalCordSimulator>
    //                 <canvas ref={canvasRef} style={{ display: "block" }} />
    //             </VocalCordSimulator>
    //         </RendererProvider>
    //     </RecorderProvider>
    // );
};
