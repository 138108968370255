import { Flex } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { usePlayer } from "../context/PlayerContext";
import { eventBus } from "../events/EventBus";

export const Oscilloscope = () => {
    const { analyser } = usePlayer();

    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
        eventBus.on("play", visualize);

        return () => {
            eventBus.off("play", visualize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function visualize() {
        const canvas = canvasRef.current;

        if (!analyser || !canvas) return;

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        const draw = () => {
            analyser.getByteFrequencyData(dataArray);
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            const barWidth = (canvas.width / bufferLength) * 2.5;
            let x = 0;

            dataArray.forEach((barHeight) => {
                ctx.fillStyle = `rgb(${barHeight + 100}, 50, 50)`;
                ctx.fillRect(
                    x,
                    canvas.height - barHeight / 2,
                    barWidth,
                    barHeight / 2
                );
                x += barWidth + 1;
            });

            requestAnimationFrame(draw);
        };

        draw();
    }

    return (
        <Flex justify="center">
            <canvas
                ref={canvasRef}
                width={300}
                height={100}
                style={{ width: "50%" }}
            ></canvas>
        </Flex>
    );
};
