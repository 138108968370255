import { createContext, PropsWithChildren, useContext, useState } from "react";
import { Formant } from "../types/Formants";

type PlayerContextType = {
    analyser: AnalyserNode;
    currentFormant: Formant | undefined;
    playFormant: (formant: Formant) => void;
    stopSounds: () => void;
    isPlaying: boolean;
};

const PlayerContext = createContext<PlayerContextType | undefined>(undefined);

export const PlayerProvider = ({ children }: PropsWithChildren) => {
    const [currentFormant, setCurrentFormant] = useState<Formant | undefined>();
    const [isPlaying, setIsPlaying] = useState(false);

    const gain = 5;
    const baseFrequency = 100;
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();

    var gainNode: GainNode;
    var oscillatorNode: OscillatorNode;
    var filterNodes: BiquadFilterNode[] = [];

    const createFilterChain = (
        audioContext: AudioContext,
        formants: number[]
    ) => {
        let previousNode: AudioNode | null = null;

        formants.forEach((frequency) => {
            const f = audioContext.createBiquadFilter();
            f.type = "bandpass";
            f.frequency.value = frequency;
            f.Q.value = 10;

            const fg = audioContext.createGain();
            fg.gain.value = gain / formants.length;

            (previousNode || oscillatorNode)?.connect(f).connect(fg);
            previousNode = fg;
            filterNodes.push(f);
        });

        return previousNode;
    };

    const playFormant = (formant: Formant) => {
        setCurrentFormant(formant);
        stopSounds();

        gainNode = audioContext.createGain();
        gainNode.gain.value = gain;
        gainNode.connect(audioContext.destination);
        gainNode.connect(analyser);

        oscillatorNode = audioContext.createOscillator();
        oscillatorNode.type = "sawtooth";
        oscillatorNode.frequency.value = baseFrequency;

        const lastNode = createFilterChain(audioContext, formant.frequencies);
        (lastNode || oscillatorNode).connect(gainNode);

        oscillatorNode.start();
        setIsPlaying(true);
    };

    const stopSounds = () => {
        filterNodes.forEach((filter) => filter.disconnect());
        filterNodes = [];

        if (oscillatorNode) {
            oscillatorNode.stop();
            oscillatorNode.disconnect();
        }

        if (gainNode) {
            gainNode.disconnect();
        }

        analyser.disconnect();
        setIsPlaying(false);
    };

    return (
        <PlayerContext.Provider
            value={{
                currentFormant,
                isPlaying,
                analyser,
                playFormant,
                stopSounds,
            }}
        >
            {children}
        </PlayerContext.Provider>
    );
};

export const usePlayer = () => {
    const context = useContext(PlayerContext);
    if (!context) {
        throw new Error("usePlayer must be used within a PlayerProvider");
    }

    return context;
};
