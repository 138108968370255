export interface Formant {
    name:
        | "LAST"
        | "LIGHT"
        | "LOST"
        | "LET"
        | "LOVE"
        | "LOW"
        | "LIVE"
        | "LEARN"
        | "LOOK"
        | "LEASE"
        | "LOOSE";
    frequencies: number[];
}

export const LAST: Formant = { frequencies: [730, 1090, 2440], name: "LAST" };
export const LIGHT: Formant = { frequencies: [300, 870, 2240], name: "LIGHT" };
export const LOST: Formant = { frequencies: [600, 950, 2600], name: "LOST" };
export const LET: Formant = { frequencies: [400, 1000, 2500], name: "LET" };
export const LOVE: Formant = { frequencies: [600, 850, 2400], name: "LOVE" };
export const LOW: Formant = { frequencies: [350, 800, 2200], name: "LOW" };
export const LIVE: Formant = { frequencies: [500, 900, 2000], name: "LIVE" };
export const LOOK: Formant = { frequencies: [400, 850, 2100], name: "LOOK" };
export const LEARN: Formant = { frequencies: [450, 800, 2300], name: "LEARN" };
export const LEASE: Formant = { frequencies: [300, 850, 2700], name: "LEASE" };
export const LOOSE: Formant = { frequencies: [350, 900, 2500], name: "LOOSE" };
