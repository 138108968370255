import {
    Formant,
    LAST,
    LEARN,
    LEASE,
    LET,
    LIGHT,
    LIVE,
    LOOK,
    LOOSE,
    LOST,
    LOVE,
    LOW,
} from "./Formants";

export interface FormantFamily {
    name: "F1" | "F2" | "F3" | "F4";
    formants: Formant[];
}

export const F1: FormantFamily = { name: "F1", formants: [LAST, LIGHT, LOST] };
export const F2: FormantFamily = { name: "F2", formants: [LET, LOVE, LOW] };
export const F3: FormantFamily = { name: "F3", formants: [LIVE, LEARN, LOOK] };
export const F4: FormantFamily = { name: "F4", formants: [LEASE, LOOSE] };

export const FORMANT_FAMILIES = [F1, F2, F3, F4];
