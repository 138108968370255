import { Button } from "@chakra-ui/react";
import { usePlayer } from "../context/PlayerContext";
import { eventBus } from "../events/EventBus";
import { Formant } from "../types/Formants";

export interface FormantButtonProps {
    formant: Formant;
}

export const FormantButton = ({ formant }: FormantButtonProps) => {
    const { currentFormant, isPlaying } = usePlayer();

    return (
        <Button
            key={formant.name}
            onClick={() => {
                if (isPlaying && currentFormant === formant) {
                    eventBus.emit("stop");
                } else {
                    eventBus.emit("play", formant);
                }
            }}
            mb={2}
            width="100%"
            colorScheme={
                currentFormant === formant && isPlaying ? "green" : "gray"
            }
        >
            {formant.name}
        </Button>
    );
};
