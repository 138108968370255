import { SimpleGrid, VStack } from "@chakra-ui/react";
import { useEffect } from "react";
import { usePlayer } from "../context/PlayerContext";
import { eventBus } from "../events/EventBus";
import { FORMANT_FAMILIES } from "../types/FormantFamilies";
import { FormantFamilyCard } from "./FormantFamilyCard";
import { Oscilloscope } from "./Oscilloscope";

export const FormantSynth = () => {
    const { playFormant, stopSounds } = usePlayer();

    useEffect(() => {
        eventBus.on("play", playFormant);
        eventBus.on("stop", stopSounds);

        return () => {
            eventBus.off("play", playFormant);
            eventBus.off("stop", stopSounds);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <VStack spacing={4} align="stretch">
            <SimpleGrid minChildWidth={200} columns={4} spacing={4} m={4}>
                {FORMANT_FAMILIES.map((family) => (
                    <FormantFamilyCard key={family.name} family={family} />
                ))}
            </SimpleGrid>

            <Oscilloscope />
        </VStack>
    );
};
